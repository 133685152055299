// App.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';

function App() {
  const [assetsList, setAssetsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAssets = async () => {
      try {
        const response = await axios.get('/api/check-assets/');
        setAssetsList(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAssets();
  }, []);

  const getColor = (action) => {
    return action.includes('BUY') ? 'green' : action.includes('SELL') ? 'red' : 'black';
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="App">
      <h1>Assets List</h1>
      <span style={{padding: '5px'}}>================================</span>
      <ul>
        {assetsList.map((item, index) => (
          <>
          <li key={item.id}><b>{item.asset}</b> &rarr; SMA: <span style={{ color: getColor(item.smaAction) }}><b><small>{item.smaAction}</small></b></span>
          &rarr; RSI: <span style={{ color: getColor(item.rsiAction) }}><b><small>{item.rsiAction}</small></b></span>
          {/* &rarr; MACD: <span style={{ color: getColor(item.macdAction) }}><b><small>{item.macdAction}</small></b></span> */}
          &rarr; BBands: <span style={{ color: getColor(item.bollingerBandsAction) }}><b><small>{item.bollingerBandsAction}</small></b></span></li>
          <span style={{padding: '5px'}}>==============================</span>
          </>
        ))}
      </ul>
      <span>legend: LSS = lastSmaShort, LSL = lastSmaLong, LM: lastMacd, LS: lastSignal, LC: lastClose, LUB: lastUpperBand, LLB: lastLowerBand</span>
    </div>
  );
}

export default App;
